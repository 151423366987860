import React, { useEffect, useState } from "react";
import { PaginatedList } from 'react-paginated-list';
import { Container, Header, Segment } from 'semantic-ui-react'
 
function Books() {
  const [books, setBooks] = useState([]);
  const [results, setResults] = useState([]);
  const [term, setTerm] = useState("");

  const handleTermChange = event => {
    setTerm(event.target.value);
  };

  const renderBook = (book) => {
    var title = "";
    if (book.author !== "") {
      title = " - by " + book.author;
    }

    return (
      <Segment>
        <a href={"https://bookshop.org/books?keywords=" + book.title + " by " + book.author} target="_blank" key={book.title}>{book.title}</a>{title}
        <p><i>{book.comments}</i></p>
      </Segment>);
  };

  useEffect(() => {
    fetch("/transformed.json")
      .then(res => res.json())
      .then(
        (result) => {
          setBooks(result);
          setResults(result);
        }
      );
  }, []);

  useEffect(() => {
    const caseInsensitiveIndexOf = function(big, little) {
      return big.toLowerCase().indexOf(little.toLowerCase());
    };

    const results = books.filter(book => {
      const searchFrom = book.title + " " + book.author + " " + book.comments;
      return caseInsensitiveIndexOf(searchFrom, term) >= 0;
    });

    setResults(results);
  }, [term]);
 
  return (
    <div>
      <div class="ui vertical header segment">
        <div class="ui container">
          <Header>Last Night's Book</Header>
        </div>
      </div>

      <Container style={{ padding: '2em 0em' }}>
        <p>Inclusive children’s books that dismantle white supremicist, racist, sexist, adultist, misogynist, capitalist, xenophobic, transphobic, or otherwise exclusive, fearful rhetoric by addressing these topics directly or indirectly; or by featuring marginalized populations where we most usually see folks who have more privilege. Diversify, decolonize, and unsettle your bookshelf.</p>

        <input key="search" type="text" placeholder="Search" onChange={handleTermChange} value={term} /><br /><br />
        <PaginatedList
          list={results}
          itemsPerPage={10}
          renderList={(list) => (
            <div class="ui raised segments">
              <>
                {list.map((book) => {
                  return renderBook(book);
                })}
              </>
              </div>
          )}
        />
      </Container>
      <div class="ui inverted vertical footer segment">
        <div class="ui container">
            <a href="mailto:info@lastnightsbook.com">Contact</a> - Read to your kids
        </div>
    </div>
    </div>
  );
}

export default Books;