import logo from './logo.svg';
import './App.css';
import Books from './Components/Books';
import Amplify from "@aws-amplify/core";
import 'semantic-ui-css/semantic.min.css'

import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

export default () => (<Books />);